import {useEffect} from 'react'

import {useContentfulInteractionLazyQuery} from 'src/graphql-generated'
import useViewer from 'src/hooks/useViewer'

const useNextJourneySteps = () => {
  const {loggedIn} = useViewer()
  // NOTE: we need `network-only` cache policy since the client doesn't know if UpNext
  // has changed since last time it was called.
  const [getNextSteps, {data, called, loading}] =
    useContentfulInteractionLazyQuery({fetchPolicy: 'network-only'})

  useEffect(() => {
    if (!loggedIn || called) {
      return
    }

    getNextSteps()
  }, [called, getNextSteps, loggedIn])

  if (!loggedIn) {
    return {loggedIn, loading: false} as const
  }

  if (loading) {
    return {loading, loggedIn} as const
  }

  return {
    loggedIn,
    loading,
    upNext: data,
  } as const
}

export default useNextJourneySteps
