import CoreTeal from 'assets/svgs/core-teal.svg'
import CoreRed from 'assets/svgs/core-red.svg'
import CoreGreenYellowSVG from 'assets/svgs/core-green-yellow.svg'
import styled from '@emotion/styled'

export const UNDERLINE_MAP = [CoreTeal, CoreRed, CoreGreenYellowSVG] as const

export const Underline = styled.em<{source: string; width?: number}>(
  {
    width: 'fit-content',
    borderImageRepeat: 'stretch',
    borderImageSlice: '100% 0',
    borderBottom: '0.3rem solid',
    font: 'inherit',
  },
  ({source, width = 20}) => ({
    borderImageSource: `url(${source})`,
    borderImageWidth: `0 0 ${width}px 0`,
  })
)
