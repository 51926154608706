import {useStaticQuery, graphql} from 'gatsby'

import {
  AllContentfulJourneyQuery,
  ContentfulJourney,
  ContentfulJourneyTile,
  ContentfulMilestone,
} from 'src/graphql-generated'

type NextData = {
  journey?: ContentfulJourney
  milestone?: ContentfulMilestone
  tiles?: ContentfulJourneyTile[]
}

const preorderTraversal = (node: any, result: any = []) => {
  if (!node) return result
  if (
    node.__typename === 'ContentfulJourney' ||
    node.__typename === 'ContentfulMilestone' ||
    node.__typename === 'ContentfulJourneyTile'
  ) {
    result.push(node)
  }

  const children = node.contentfulchildren || []
  children.forEach((child: any) => {
    preorderTraversal(child, result)
  })

  return result
}

const useInteractedJourney = () => {
  const data = useStaticQuery<AllContentfulJourneyQuery>(graphql`
    query InteractedJourney {
      allContentfulJourney {
        nodes {
          __typename
          id: contentful_id
          permalink
          name
          contentfulchildren {
            ... on ContentfulMilestone {
              __typename
              id: contentful_id
              name
              permalink
              contentfulchildren {
                ... on ContentfulJourneyTile {
                  __typename
                  id: contentful_id
                  permalink
                  tile {
                    id
                    permalink
                    title
                    summary {
                      summary
                    }
                    heroRemoteUrl
                  }
                }
                ... on ContentfulSubmilestone {
                  __typename
                  contentfulchildren {
                    ... on ContentfulJourneyTile {
                      __typename
                      id: contentful_id
                      permalink
                      tile {
                        id
                        permalink
                        title
                        summary {
                          summary
                        }
                        heroRemoteUrl
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const allData: any = preorderTraversal({
    contentfulchildren: data.allContentfulJourney?.nodes,
  })

  const upNext = (contentType?: string, contentId?: string) => {
    const nextData: NextData = {}

    if (!contentType || !contentId) {
      nextData.tiles = allData
        .filter((data: any) => data.__typename === 'ContentfulJourneyTile')
        .slice(0, 3)
    }

    const currentIndex = allData.findIndex(
      (item: any) => item.__typename === contentType && item.id === contentId
    )

    if (currentIndex !== -1) {
      const nextTiles = allData
        .slice(currentIndex)
        .filter((data: any) => data.__typename === 'ContentfulJourneyTile')
        .slice(0, 3)
      if (nextTiles.length > 0) {
        nextData.tiles = nextTiles
      } else {
        nextData.tiles = allData
          .filter((data: any) => data.__typename === 'ContentfulJourneyTile')
          .slice(0, 3)
      }
    } else {
      nextData.tiles = allData
        .filter((data: any) => data.__typename === 'ContentfulJourneyTile')
        .slice(0, 3)
    }

    const tileIndex = allData.findIndex(
      (item: any) => item.id === (nextData.tiles && nextData.tiles[0].id)
    )
    nextData.milestone = allData
      .slice(0, tileIndex)
      .reverse()
      .find((item: any) => item.__typename === 'ContentfulMilestone')

    nextData.journey = allData
      .slice(0, tileIndex)
      .reverse()
      .find((item: any) => item.__typename === 'ContentfulJourney')

    return nextData
  }

  return {upNext}
}

export default useInteractedJourney
