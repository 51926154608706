import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'

import * as breakpoints from 'src/styles/mediaQuery'
import {theme} from 'src/styles/theme'

const query = graphql`
  query DashboardHeroImage {
    smallMobileImage: file(relativePath: {eq: "hero-dashboard_sm.png"}) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileImage: file(relativePath: {eq: "hero-dashboard.png"}) {
      childImageSharp {
        fluid(maxWidth: 1050, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    desktopImage: file(relativePath: {eq: "hero-dashboard_superwide.png"}) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const DashboardHeroImage = () => {
  const data = useStaticQuery(query)

  const sources = [
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: ${theme.breakpoints[2]})`,
    },
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: `(min-width: 580px)`,
    },
    data.smallMobileImage.childImageSharp.fluid,
  ]

  return (
    <Img
      fluid={sources}
      css={{
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '100vw',
        minHeight: '300px',
        [breakpoints.large]: {
          minHeight: '440px',
        },
      }}
    />
  )
}

export default DashboardHeroImage
