import {Link} from 'gatsby'
import BarsFlair from 'assets/svgs/dashboard-flair/upnext-bars.svg'

import useNextJourneySteps from 'src/hooks/useNextJourneySteps'
import {LoadingContentPlaceholder} from 'src/components/Loading'
import {Grid, Flex, Box} from 'src/components/Box'
import {Header2, Lede} from 'src/components/text'
import Article from 'src/components/Journey/Article'
import BackgroundImage from 'src/components/BackgroundImage'
import {ArrowIconToRight} from 'src/components/icons'
import useInteractedJourney from 'src/hooks/useInteractedJourney'

const UpNextItems = () => {
  const result = useNextJourneySteps()
  const nextContent = useInteractedJourney()

  if (result.loading) {
    return (
      <LoadingContentPlaceholder appearAfter="0.25s" mt="2" size="medium" />
    )
  }

  if (!result.upNext) {
    return null
  }

  const {journey, milestone, tiles} = nextContent.upNext(
    result.upNext?.contentfulInteraction?.contentType,
    result.upNext?.contentfulInteraction?.contentId
  )

  return (
    <>
      <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
        {tiles &&
          tiles.map((t) => (
            <Box gridColumn={{_: 'span 2', md: 'span 1'}} key={t.id}>
              {t.tile && (
                <Article
                  title={t.tile.title}
                  description={t.tile.summary?.summary}
                  heroUrl={t.tile.heroRemoteUrl}
                  link={t.permalink}
                />
              )}
            </Box>
          ))}
      </Grid>
      <Grid gridGap="4" maxWidth={{md: '675px'}} margin={{md: '0 auto'}}>
        {milestone && (
          <Lede>
            More in the series{' '}
            <Link to={milestone.permalink}>
              {milestone.name}
              <ArrowIconToRight width="1em" ml="1" />
            </Link>
          </Lede>
        )}
        {journey && (
          <Lede>
            Check out other series in the goal{' '}
            <Link to={journey.permalink}>
              {journey.name}
              <ArrowIconToRight width="1em" ml="1" />
            </Link>
          </Lede>
        )}
      </Grid>
    </>
  )
}

const UpNext = () => (
  <Grid gridGap="5">
    <Flex justifyContent={{md: 'center'}}>
      <Box position="relative">
        <BackgroundImage
          url={BarsFlair}
          position="absolute"
          bottom={0}
          right={{_: 0, md: -120}}
          width={85}
          height={85}
        />
        <Box width={{_: '80%', md: '100%'}}>
          <Header2 as="h1" textAlign={{md: 'center'}}>
            Up Next
          </Header2>
          <Lede>Explore our picks to help you on your next step.</Lede>
        </Box>
      </Box>
    </Flex>
    <UpNextItems />
  </Grid>
)

export default UpNext
