import {useEffect} from 'react'
import {navigate} from 'gatsby'

import {CurrentUserFragment} from 'src/graphql-generated'
import useViewer from 'src/hooks/useViewer'
import {localPaths} from 'src/urls'

import {LoadingContentPlaceholder} from './Loading'

interface AuthRequiredProps {
  children: (props: {
    currentUser: CurrentUserFragment
  }) => React.ReactElement | null
  onUnauthenticated?: () => void
}

const AuthRequired = ({
  children,
  onUnauthenticated = () => navigate(localPaths.login),
}: AuthRequiredProps) => {
  const result = useViewer()

  useEffect(() => {
    if (result.viewer === 'not-logged-in') {
      onUnauthenticated()
    }
  }, [onUnauthenticated, result.loggedIn, result.viewer])

  if (result.loading || !result.loggedIn) {
    return <LoadingContentPlaceholder size="large" mt="9" />
  }

  return children({currentUser: result.viewer.student})
}

export default AuthRequired
