import styled from '@emotion/styled'

import * as styles from 'src/styles'
import * as breakpoints from 'src/styles/mediaQuery'

export const HeroCardContainer = styled.div(({theme}) => ({
  borderRadius: 19,
  backgroundColor: theme.colors.white,
  ...styles.shadow.homeHeroCardShadow,
  maxWidth: '400px',
  margin: '0 auto',
  top: '-5rem',
  // transform: 'translateX(-50%)',
  // position: 'absolute',
  width: styles.cssCalc('100%', '-', '4rem'),
  [breakpoints.large]: {
    margin: 0,
    top: '0',
    bottom: theme.space[5],
    padding: theme.space[5],
    right: 0,
    left: 'auto',
    transform: 'none',
  },
}))
