import {Helmet} from 'react-helmet-async'
import {useStaticQuery, graphql} from 'gatsby'
import {SeoQuery} from 'gatsby-graphql-types-generated'
import compact from 'lodash/compact'

interface Props {
  description?: string
  lang?: string
  meta?: {name: string; content: string}[]
  permalink?: string
  title: string
}

const SEO: React.FC<Props> = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
  permalink,
}) => {
  const {site} = useStaticQuery<SeoQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site?.siteMetadata?.description || ''
  const titleTemplate = site?.siteMetadata?.title || ''

  const author = site?.siteMetadata?.author

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${titleTemplate}`}
      meta={compact([
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        author && {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...meta,
      ])}
    >
      {permalink && (
        <link
          rel="canonical"
          href={[process.env.GATSBY_CANONICAL_BASE_URL, permalink].join('')}
        />
      )}
    </Helmet>
  )
}

export default SEO
