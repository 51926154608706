import {navigate, PageRendererProps} from 'gatsby'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import UpNext from 'src/components/Dashboard/UpNext'
import GoalsAndResources from 'src/components/Dashboard/GoalsAndResources'
import AuthRequired from 'src/components/AuthRequired'
import {localPaths} from 'src/urls'
import {Grid, Box} from 'src/components/Box'
import {FlairProvider} from 'src/context/FlairContext'
import {Divider} from 'src/components/dividers'
import {HeroCardContainer} from 'src/components/HomePage/HeroCardContainer'
import DashboardHeroImage from 'src/components/Dashboard/HeroImage'
import {Header3, Body} from 'src/components/text'

const Dashboard: React.FunctionComponent<{}> = () => (
  <>
    <Box position="relative" left="50%" css={{transform: 'translate(-50%, 0)'}}>
      <DashboardHeroImage />
      <HeroCardContainer>
        <Header3>Welcome to your Get Schooled dashboard!</Header3>
        <Body mt="2">
          Check out our recommendations to help you with your goals, or explore
          our whole content library.
        </Body>
      </HeroCardContainer>
    </Box>
    <Grid
      gridGap={{_: 5, lg: 7}}
      mt={{_: '140px', sm: '220px', md: '190px', lg: 6}}
    >
      <UpNext />
      <Divider my="2" />
      <GoalsAndResources />
    </Grid>
  </>
)

const DashboardPage = ({location}: PageRendererProps) => {
  return (
    <Layout footerMargin="7">
      <SEO title="Get Schooled" />
      <FlairProvider seed={1}>
        <AuthRequired
          onUnauthenticated={() =>
            navigate(localPaths.login, {state: {returnTo: location.pathname}})
          }
        >
          {() => <Dashboard />}
        </AuthRequired>
      </FlairProvider>
    </Layout>
  )
}

export default DashboardPage
