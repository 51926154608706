import {Link} from 'gatsby'
import take from 'lodash/take'
import {Maybe, Cms_JourneyIcon} from 'gatsby-graphql-types-generated'
import ResourceOrangeSVG from 'assets/svgs/resource-orange-highlight.svg'
import GoalsFlair from 'assets/svgs/dashboard-flair/goals-flair.svg'

import {Grid, Flex, Box} from 'src/components/Box'
import {Header3, Body} from 'src/components/text'
import useCoreJourneys from 'src/hooks/useCoreJourneys'
import JourneyIcon from 'src/components/JourneyIcon'
import {UNDERLINE_MAP, Underline} from 'src/components/ResourceUnderline'
import {ArrowIconToRight} from 'src/components/icons'
import {localPaths} from 'src/urls'
import {ActionLink} from 'src/components/Action'
import BackgroundImage from 'src/components/BackgroundImage'

const Goals = () => {
  const coreJourneys = useCoreJourneys()
  return (
    <>
      {coreJourneys.map((journey, index) => {
        const underlineSource = UNDERLINE_MAP[index % UNDERLINE_MAP.length]
        const items = take(journey.milestones, 4).map((m) => ({
          to: m.permalink,
          name: m.name,
        }))
        return (
          <Section
            icon={journey.icon}
            underlineSource={underlineSource}
            key={journey.id}
            header={journey.name}
            description={journey.shortDescription?.shortDescription}
            items={items}
            seeAllLink={journey.permalink}
          />
        )
      })}
    </>
  )
}

interface SectionProps {
  icon?: Maybe<Cms_JourneyIcon>
  description?: Maybe<string>
  header: string
  items: {name: string; to: string}[]
  underlineSource: string
  seeAllLink?: string
}

const Section = ({
  icon,
  header,
  items,
  underlineSource,
  seeAllLink,
  description,
}: SectionProps) => (
  <Grid gridGap="2" alignContent="flex-start">
    <Flex alignItems="center">
      {icon && (
        <JourneyIcon icon={icon} mr="1" height="1.75rem" width="1.75rem" />
      )}
      <Underline source={underlineSource} width={10}>
        <Header3>{header}</Header3>
      </Underline>
    </Flex>

    {description && <Body>{description}</Body>}

    <Grid as="ol" gridGap="1">
      {items.map((i) => (
        <li key={i.to}>
          <Link to={i.to}>{i.name}</Link>
        </li>
      ))}
    </Grid>

    {seeAllLink && (
      <ActionLink to={seeAllLink}>
        <Body as="span" fontWeight="medium" color="grey6">
          See all
          <ArrowIconToRight width="1em" ml="1" />
        </Body>
      </ActionLink>
    )}
  </Grid>
)

const RESOURCE_ITEMS = [
  {name: 'Text Us for Help', to: localPaths.sendText},
  {
    name: 'College Application Help',
    to: localPaths.collegeAppHelp,
  },
  {name: 'Resume Help', to: localPaths.resumeHelp},
  {name: 'Job Search', to: localPaths.jobSearch},
]

const GoalsAndResources = () => (
  <Grid position="relative" gridGap="7" justifyContent={{lg: 'center'}}>
    <Flex justifyContent={{_: 'flex-start', md: 'center'}}>
      <Box position="relative">
        <BackgroundImage
          position="absolute"
          url={GoalsFlair}
          zIndex={1}
          right={-80}
          top={-25}
          width={100}
          height={100}
        />
        <Header3 textAlign="center" as="h2">
          Goals & Resources
        </Header3>
      </Box>
    </Flex>
    <Grid
      gridGap="7"
      gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      maxWidth={{lg: '675px'}}
    >
      <Goals />
      <Section
        header="Resources"
        underlineSource={ResourceOrangeSVG}
        items={RESOURCE_ITEMS}
      />
    </Grid>
  </Grid>
)

export default GoalsAndResources
